import { Directive, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
declare var google: any;

@Directive({
  selector: '[google-place]'
})
export class GooglePlaceDirective implements OnInit {

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;
  constructor(elRef: ElementRef, private mapsAPILoader: MapsAPILoader) {
    // elRef will get a reference to the element where
    // the directive is placed
    this.element = elRef.nativeElement;
  }

  ngOnInit() {


    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.element,  {
        // componentRestrictions: { country: 'US' },
        // types: ['geocode']  // 'establishment' / 'address' / 'geocode'
    });
      // Event listener to monitor place changes in the input
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        // Emit the new address object for the updated place
        this.onSelect.emit(autocomplete.getPlace());
      });
    });
  }

}

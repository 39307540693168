import { throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { FileSnippet } from "./image-processing-utils";

// Error handling
export function errorHandl(error: HttpErrorResponse) {
  let errorMessage = "";
  // console.log(error.instanceof());
  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

    if (error.error) {
      console.log("if error", error);
      if (error.error.message) {
        errorMessage = error.error.message;
      }
    }
  }
  return throwError(errorMessage);
}

// setting form data
// export function setFormData(imgFiles, staffData, dataRef) {
//   const formData = new FormData();
//   Object.keys(imgFiles).forEach(key => {
//           if (imgFiles[key] !== null) {
//               formData.append(key, imgFiles[key], imgFiles[key].name);
//           }
//       });
//   Object.keys(staffData).forEach(key => formData.append(`${dataRef}[${key}]`, staffData[key]));
//   return formData;
// }

export function setOnlyFieldFormData(staffData, dataRef) {
  const formData = new FormData();
  Object.keys(staffData).forEach(key =>
    formData.append(`${dataRef}[${key}]`, staffData[key])
  );
  return formData;
}

// export function setFormData( data, keyName) {
//   const formData: FormData = new FormData();
//   Object.keys(data).forEach((key) => {
//     if (data[key]  instanceof Date) {
//       // Skip this Case
//     }else if (data[key]  instanceof FileSnippet) {
//       console.log("File Data ", data[key]);
//       formData.append(key, data[key].file, data[key].file.name);
//     } else {
//       formData.append(`${keyName}[${key}]`, data[key]);
//     }
//   });
//   return formData;
// }

export function setFormData(data) {
  const formData: FormData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof FileSnippet) {
      formData.append(key, data[key].file, data[key].file.name);
    } else {
      formData.append(`${key}`, data[key]);
    }
  });
  return formData;
}

export function downloadImage(uri, name) {
  var link = document.createElement("a");
  console.log(uri, name);
  link.download = name;
  link.href = uri;
  link.click();
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private appNotification = new BehaviorSubject(null);
  private signupNotification = new BehaviorSubject(null);

  constructor() { }

  getNotifiction(): Observable<any> {
    return this.appNotification.asObservable();
  }

  pushNotification(appNotification) {
    this.appNotification.next(appNotification);
  }

  getSignupNotification(): Observable<any> {
    return this.signupNotification.asObservable();
  }

  pushSignupNotification(notification) {
    this.signupNotification.next(notification);
  }
}

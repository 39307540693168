import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GooglePlaceDirective } from './directives/google-places-directive/google-place.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
// we are using this in edit profile for all time zone list
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';

import { AgmDirectionModule } from 'agm-direction';
import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { AgmOverlays } from 'agm-overlays';
import {
  NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig,
  SPINNER, POSITION, PB_DIRECTION
} from 'ngx-ui-loader';
import { AuthorizationGuard } from './services/auth-guard/authorization-guard.service';
import { SocketService } from './services/socket-service/socket-service.service';
import { MySocketWrapper } from './services/socket-service/socket-wrapper';
import { TokenInterceptorService } from './services/token-interceptor/token-interceptor.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AddDriverComponent } from './components/drivers/add-driver/add-driver.component';
import {
  OwlMomentDateTimeModule,
  OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS
} from 'ng-pick-datetime-moment';
import { DriverDetailsComponent } from './components/drivers/driver-details/driver-details.component';
import { AddFleetComponent } from './components/fleets/add-fleet/add-fleet.component';
import { FleetDetailsComponent } from './components/fleets/fleet-details/fleet-details.component';
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { AddDriverDocumentsComponent } from './components/drivers/add-driver-documents/add-driver-documents.component';
import { TimezonePipe } from './pipes/timezone-pipe/timezone.pipe';
import { OutsideClickDirective } from './directives/click-outside/click-outside.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { FilterByKeyPipe } from './pipes/filter-by-key-pipe/filter-by-key-pipe';
import { NgxStarsModule } from 'ngx-stars';
import { ToastMessagesComponent } from './services/toast-messages/toast-messages.component';
import { HomeComponent } from './components/home/home.component';
import { FinishedComponent } from './components/finished/finished.component';
import { SigninComponent } from './components/shared/signin/signin.component';

export const MY_MOMENT_FORMATS = {
  fullPickerInput: 'MM/DD/YYYY HHmm',
  parseInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};


const ngxUiLoaderConfig: NgxUiLoaderConfig  = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 80,
  bgsType: 'ball-spin-clockwise',
  blur: 0,
  fgsColor: '#727cf5',
  fgsPosition: 'center-center',
  fgsSize: 40,
  fgsType: 'ball-spin',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 150,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40,40,40,0)',
  pbColor: '#727cf5',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
};


@NgModule({
  declarations: [
    AppComponent,
    GooglePlaceDirective,
    AddDriverComponent,
    DriverDetailsComponent,
    AddFleetComponent,
    FleetDetailsComponent,
    AddDriverDocumentsComponent,
    TimezonePipe,
    OutsideClickDirective,
    FilterByKeyPipe,
    ToastMessagesComponent,
    HomeComponent,
    FinishedComponent,
    SigninComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgHttpLoaderModule, 

    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ exclude: [],
     showForeground: true }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA',
      libraries: ['places']
    }),
    SocketIoModule,
    AgmDirectionModule,
    BrowserAnimationsModule,

    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    AgmOverlays,
    TimezonePickerModule,
    NgSelectModule,
    NgxStarsModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: AuthorizationGuard, useClass: AuthorizationGuard },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    {
      provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
      useValue: { useUtc: false }
    },
    MySocketWrapper,
    SocketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

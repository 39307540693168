import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
  CanActivateChild,
  CanDeactivate, CanLoad, UrlTree, Route, UrlSegment
} from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';
import { SharedDataService } from '../shared-service/shared-data.service';
import { SocketService } from '../socket-service/socket-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {


  constructor(private authService: AuthService,
    private router: Router,
    private sharedData: SharedDataService,
    private activatedRoute: ActivatedRoute,
    private socket: SocketService
  ) {
  }

  // @ts-ignore
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  // @ts-ignore
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  // @ts-ignore
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  checkUserLogin(route: ActivatedRouteSnapshot,
    url: any) {

      return of(true);
    return this.authService.verifyToken()
      .pipe(
        map((user: any) => {
          // console.log('Authorization Guard: ', data);
          console.log("One",user.roles[0], ' -  ', route.data.role)
          if (user && this.authService.getAllPanelRoles().includes(user.roles[0])) {
            this.socket.connectSocket();
            // this.sharedData.saveUser(user);

            if (route.data && route.data.role) {
              if (route.data.role.includes(user.roles[0])) {
                console.log("Two")
                return true;
              } else {
                console.log("Three")
                this.router.navigate(['/']);
                return false;
              }

            } else {
              console.log("Four")
              this.socket.disconnectSocket();
              localStorage.removeItem('token');
              this.router.navigate(['/signin']);
              return false;
            }

          } else {
            console.log("Five")
            this.socket.disconnectSocket();
            localStorage.removeItem('token');
            this.router.navigate(['/signin']);
            return false;
          }
        }),
        catchError((error) => {
          console.log("six")
          this.socket.disconnectSocket();
          localStorage.removeItem('token');
          this.router.navigate(['/signin']);
          return of(false);
        })
      );

  }
}


import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { imageFileProcessing } from "src/app/utils/image-processing-utils";
import Swal from "sweetalert2";
import { DriverService } from "../service/driver.service";

@Component({
  selector: "app-add-driver",
  templateUrl: "./add-driver.component.html",
  styleUrls: ["./add-driver.component.css"],
})
export class AddDriverComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  isPasswordShow = false;
  profileImageFile;
  profileImageUrl;
  sharedUserData;
  driverId;
  today;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private driverService: DriverService,
    private authService: AuthService,
    private sharedService: SharedDataService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.today = moment().set({hours:0,minutes:0,seconds:0}).toISOString();

    this.formGroup = this.fb.group({
      displayName: ["", Validators.required],
      contactNumber: ["", Validators.required],

      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],

      address: ["", Validators.required],
      coords: [null, Validators.required], // This makes sure that the address being selected is valid.

      newProfilePicture: [null, Validators.required],
      deviceKey: ["000000000000000"],
      deviceType: ["web"],

      dob: ['', Validators.required],
      preDob: ['']
    });

    this.sharedService.getNotifiction().subscribe((data) => {
      if (data) {
        console.log('Add driver', data);
        this.sharedUserData = data;
        if (data && data.user) {
          this.driverId = this.sharedUserData.user._id;
          delete this.sharedUserData.user.password;
          this.profileImageUrl = this.sharedUserData.user.profileImageURL;
          this.formGroup.patchValue({
            ...this.sharedUserData.user,
            coords: [0, 0],
            preDob: this.sharedUserData.user.dob ? this.sharedUserData.user.dob : ''
          });

          // Password
          this.formGroup.controls.password.clearValidators();
          this.formGroup.controls.password.updateValueAndValidity();

          // Profile Image
          this.formGroup.controls.newProfilePicture.clearValidators();
          this.formGroup.controls.newProfilePicture.updateValueAndValidity();
        }
      }
    });
  }

  submit() {
    this.isSubmitted = true;
    console.log("Form :", this.formGroup.value);
    if (this.formGroup.invalid) {
      return;
    }

    this.driverService
      .saveDriver(this.driverId, this.formGroup.value)
      .subscribe(
        (data) => {
          if (data) {
            Swal.fire({
              title: "Alert",
              html: "Operation successful",
              icon: "success",
            }).then((c) => {
          });
            this.authService.setToken(data.token);
            if (this.driverId) {
              this.sharedService.pushNotification({
                ...this.sharedUserData,
                token: data.token,
                user: { ...data.driver, step: 0 },
              });
            } else {
              this.sharedService.pushNotification({
                ...this.sharedUserData,
                token: data.token,
                user: data.driver,
              });
            }
          }
        },
        (err) => {
          Swal.fire({
            title: "Alert",
            html: err,
            icon: "error",
          }).then((data) => {});
        }
      );
  }

  processProfileImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.profileImageFile = result.file;
        this.profileImageUrl = result.src;
        this.formGroup.patchValue({
          newProfilePicture: this.profileImageFile,
        });
      } else {
        this.profileImageFile = null;
        this.profileImageUrl = "";
        this.formGroup.patchValue({
          newProfilePicture: null,
        });
      }
    });
  }

  setAddress(place) {
    this.formGroup.patchValue({
      address: place.formatted_address,
      coords: [place.geometry.location.lng(), place.geometry.location.lat()],
    });
  }

  onDateChange(evt, control) {
    if (evt) {
    control.setValue(moment(evt).set({hours:0,minutes:0,seconds:0}).toISOString());
    }
  }

  get form() {
    return this.formGroup.controls;
  }
}

import { FinishedComponent } from './components/finished/finished.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthorizationGuard } from "./services/auth-guard/authorization-guard.service";
import { AddDriverComponent } from "./components/drivers/add-driver/add-driver.component";
import { AddFleetComponent } from "./components/fleets/add-fleet/add-fleet.component";
import { FleetDetailsComponent } from "./components/fleets/fleet-details/fleet-details.component";
import { DriverDetailsComponent } from "./components/drivers/driver-details/driver-details.component";
import { AddDriverDocumentsComponent } from "./components/drivers/add-driver-documents/add-driver-documents.component";
import { HomeComponent } from "./components/home/home.component";
import { SigninComponent } from "./components/shared/signin/signin.component";

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: "home",
    component: HomeComponent,
    // canActivate: [AuthorizationGuard],
    data: {
      role: ["cuser", "cadmin"],
    },
  },
  {
    path: "driver",
    component: AddDriverComponent,
    // canActivate: [AuthorizationGuard],
    data: {
      role: ["cuser", "cadmin"],
    },
  },
  {
    path: "documents",
    component: AddDriverDocumentsComponent,
    // canActivate: [AuthorizationGuard],
    data: {
      role: ["cuser", "cadmin"],
    },
  },
  {
    path: "fleet",
    component: AddFleetComponent,
    // canActivate: [AuthorizationGuard],
    data: {
      role: ["cuser", "cadmin"],
    },
  },
  {
    path: "finished",
    component: FinishedComponent,
    // canActivate: [AuthorizationGuard],
    data: {
      role: ["cuser", "cadmin"],
    },
  },
  {
    path: "signin",
    component: SigninComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from '../service/driver.service';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.css']
})
export class DriverDetailsComponent implements OnInit {
  driver;
  driverAdditionalInfo;
  driverId;
  imgModalPayload;


  constructor(
    private driverService: DriverService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.driverId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDriverDetails();
    this.getDriverAdditionalInfo();
  }

  getDriverDetails() {
    this.driverService
      .getDriverById(this.driverId)
      .subscribe(data => {
        if (data) {
          this.driver = data;
        }
      });
  }

  getDriverAdditionalInfo() {
   this.driverService
   .getDriverDocuments(this.driverId)
   .subscribe(data => {
     if (data) {
      this.driverAdditionalInfo = data;
     }
   }); 
  }

}

import { Injectable } from "@angular/core";
import { Message } from "../model/message";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ToastService {
  messages = [];
  private subject: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor() {}

  getMessages(): Observable<any>  {
    return this.subject.asObservable();
  }

  sendMessage(content, style? ) {
    if(style){
    let message: Message =  new Message(content,style);
    this.messages.push(message);
    }else{
      let message: Message =  new Message(content,style);
      this.messages.push(message);
    }

    this.subject.next(this.messages);
  }

  dismissMessage(messageKey) {
     this.messages.splice(messageKey, 1);
    this.subject.next(this.messages);
  }
}

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { errorHandl, setFormData } from "src/app/utils/network-utils";
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: "root",
})
export class DriverService {
  constructor(private http: HttpClient) {}

  getDrivers(queryParams) {
    const params = new HttpParams({ fromObject: queryParams });
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.DRIVERS, { params })
      .pipe(catchError(errorHandl));
  }

  getDriverById(driverId) {
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.DRIVERS + `/${driverId}`)
      .pipe(catchError(errorHandl));
  }

  getDriverDocuments(driverId) {
    return this.http
      .get<any>(
        urls.SERVER_API_URL +
          urls.DRIVERS_ADDITIONAL_INFO +
          "/driver" +
          `/${driverId}`
      )
      .pipe(catchError(errorHandl));
  }

  saveDriver(driverId, payload) {
 

    if (driverId) {
      // Edit
      if (payload.password === '') delete payload.password ;
      const formData = setFormData(payload);

      return this.http
        .put<any>(urls.SERVER_API_URL + urls.DRIVERS + `/${driverId}`, formData)
        .pipe(catchError(errorHandl));
    } else {
      // Add
      const formData = setFormData(payload);
      return this.http
        .post<any>(urls.SERVER_API_URL + urls.DRIVERS_SIGNUP, formData)
        .pipe(catchError(errorHandl));
    }
  }

  saveDriverDocuments(additionalInfoId, payload) {
    const formData = setFormData(payload);

    if (additionalInfoId) {
      // Edit
      return this.http
        .put<any>(
          urls.SERVER_API_URL +
            urls.DRIVERS_ADDITIONAL_INFO +
            `/${additionalInfoId}`,
          formData
        )
        .pipe(catchError(errorHandl));
    } else {
      // Add
      return this.http
        .post<any>(urls.SERVER_API_URL + urls.DRIVERS_ADDITIONAL_INFO, formData)
        .pipe(catchError(errorHandl));
    }
  }

  getDriverRatings(driverId) {
    return this.http
      .get<any>(urls.SERVER_API_URL + urls.DRIVERS + `/rating/${driverId}`)
      .pipe(catchError(errorHandl));
  }
}

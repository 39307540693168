import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastService } from './toastService/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['./toast-messages.component.css']
})
export class ToastMessagesComponent implements OnInit,OnDestroy {

  messages = [];
  subscription: Subscription;
  settingInterval;

  constructor(private toast: ToastService) { }

  ngOnInit() {
   this.toast.getMessages()
     this.subscription =this.toast
     .getMessages()
      .subscribe((messages) => {
        // console.log(messages);
        this.messages  = messages;
      });

      this.settingInterval = setInterval(async () => {

        if(this.messages && (this.messages.length !== 0)){
        this.toast.dismissMessage(0)
        }
      }, 8000);

  }

  dismiss(itemKey) {
    console.log(itemKey)
    this.toast.dismissMessage(itemKey)
  }

  ngOnDestroy(): void {
    clearInterval(this.settingInterval);
  }

}

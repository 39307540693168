import { Component, ElementRef, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { AuthService } from "./services/auth-service/auth.service";
import { SharedDataService } from "./services/shared-service/shared-data.service";

declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  step;
  sharedUserData;
  currentRoute;

  constructor(
    private authService: AuthService,
    private sharedService: SharedDataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.receiveNotification();
    this.routerListener();
    const token = this.authService.getToken("token");
    if (token) {
      this.me();
    } else {
      this.router.navigateByUrl("signin");
    }
  }

  me() {
    this.authService.verifyToken().subscribe((data) => {
      if (data && data.user) {
        if (data.user && data.driveradditionalinfo && data.fleet) {
          this.sharedService.pushNotification({
            ...data,
            fleet: {
              ...data.fleet,
              vehicle :data.fleet.vehicle._id
            },
            user: {
              ...data.user,
              step: 0,
            },
          });
        } else {
          this.sharedService.pushNotification(data);
        }

        this.redriect();
      }
    });
  }

  receiveNotification() {
    this.sharedService.getSignupNotification().subscribe((data) => {
      if (data) {
        console.log(data);
        this.step = 0;
        this.redriect();
      }
    });

    this.sharedService.getNotifiction().subscribe((data) => {
      if (data) {
        console.log(data);
        this.step = data.user.step;
        this.sharedUserData = data;
      } else {
        this.sharedUserData = null;
        this.step = null;
      }
      this.redriect();
    });
  }

  routerListener() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log(">>>>>> Navi", event.url);
        this.currentRoute = event.url;
        if (this.currentRoute === "/signin") {
          this.step = null;
        }
      }
    });
  }

  redriect() {
    if (this.step === 0) {
      this.router.navigateByUrl("driver");
    } else if (this.step === 1) {
      this.router.navigateByUrl("documents");
    } else if (this.step === 2) {
      this.router.navigateByUrl("fleet");
    } else if (this.step === 3) {
      this.router.navigateByUrl("finished");
    } else {
      this.step = null;
      this.router.navigateByUrl("signin");
    }
  }
  onLogoutClick() {
    this.authService.removeToken();
    this.step = null;
    this.sharedUserData = null;
    this.redriect();
    this.sharedService.pushNotification(null);
  }

  changeStep(step) {
    this.step = step;
    this.redriect();
  }
}

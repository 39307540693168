import { environment } from "src/environments/environment";
export class urls {
    static BASE_URL = environment.BASE_URL;
    static SERVER_API_URL = urls.BASE_URL + 'api/'

    static SIGNIN_URL = 'auth/signin';
    static FORGOT_URL = 'auth/forgot';
    static CHANGE_PASSWORD_URL = 'users/password';
    static CHANGE_PROFILE_IMAGE = 'users/picture';
    static PROFILE_URL = 'users/me';

    static COMPANIES = 'companies';
    static VEHICLES = 'vehicles';
    static STAFF = 'companystaffs';
    static FLEETS = 'fleets';
    static FLEETS_BY_LOCATION = 'fleets/locations';

    static FARES = 'fares';
    static PASSWORD = 'users/password';
    static USERS = 'users';
    static USER_PICTURE = 'users/picture';
    static DRIVERS_SIGNUP = 'drivers/signup/kzcdotuw';
    static DRIVERS = 'drivers';
    static DRIVERS_ADDITIONAL_INFO = 'driveradditionalinfos';
    static RIDES = "rides"
    static GET_FAREESTIMATION = "fares/estimation"
    static PASSENGERS = "passengers"
    static SUPPORT = 'queries'
    static REPORTS = 'rides/reports'
    static SEARCH = 'search'
    static NOTIFICATIONS_URL = 'notifications';
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.css"],
})
export class SigninComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  isPasswordShow = false;
  isSignupForm = true;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private sharedService: SharedDataService,
    private router: Router,
    private socket: SocketService
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      usernameOrEmail: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      type: ["driver"],
    });
  }

  submit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    this.authService.signIn(this.formGroup.value).subscribe(
      (data) => {
        if (data) {
          this.authService.setToken(data.token);
          if (data.user && data.driveradditionalinfo && data.fleet) {
            if (data.fleet) {
              this.sharedService.pushNotification({
                ...data,
                user: {
                  ...data.user,
                  step: 0,
                },
                fleet: {
                  ...data.fleet,
                  vehicle: data.fleet.vehicle._id,
                },
              });
            } else {
              this.sharedService.pushNotification({
                ...data,
                user: {
                  ...data.user,
                  step: 0,
                },
              });
            }
          } else {
            this.sharedService.pushNotification(data);
          }
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: err,
          icon: "error",
        }).then((data) => {});
      }
    );
  }

  toggleRole() {
    this.formGroup.patchValue({
      type: this.formGroup.value.type === "admin" ? "companystaff" : "admin",
    });
  }

  onSignupClick() {
    this.sharedService.pushSignupNotification({ action: "Sigin" });
  }

  get form() {
    return this.formGroup.controls;
  }
}

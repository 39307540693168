import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DriverService } from "../../drivers/service/driver.service";
import { VehicleService } from "../../vehicles/service/vehicle.service";
import { FleetService } from "../service/fleet.service";
import Swal from "sweetalert2";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { AuthService } from "src/app/services/auth-service/auth.service";
@Component({
  selector: "app-add-fleet",
  templateUrl: "./add-fleet.component.html",
  styleUrls: ["./add-fleet.component.css"],
})
export class AddFleetComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;
  vehicles;
  fleetId;
  sharedUserData;
  constructor(
    private fb: FormBuilder,
    private fleetService: FleetService,
    private vehicleService: VehicleService,
    private driverService: DriverService,
    private router: Router,
    private sharedService: SharedDataService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      vehicle: ["", Validators.required],
      make: ["", Validators.required],
      year: ["", Validators.required],
      color: ["", Validators.required],
      vin: ["", Validators.required],
      setiDecal: ["", Validators.required],
      plateNumber: ["", Validators.required],
      plateState: ["", Validators.required],
      status: ["active", Validators.required],
      model: ["", Validators.required],
      driver: [null, Validators.required],
    });

    this.sharedService.getNotifiction().subscribe((data) => {
      if (data) {
        this.sharedUserData = data;
        this.formGroup.patchValue({
          driver: data.user._id,
        });
        if (data.fleet) {
          this.fleetId = data.fleet._id;
          this.formGroup.patchValue({
            ...data.fleet
          });
        }
      }
    });
    this.getVehicles();
  }

  getVehicles() {
    const queryParams = {
      status: "active",
    };
    this.vehicleService.getVehicles(queryParams).subscribe(
      (data) => {
        if (data && data.length) {
          this.vehicles = data;
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => {});
      }
    );
  }

  submit() {
    this.isSubmitted = true;

    console.log("Form: ", this.formGroup);

    if (this.formGroup.invalid) {
      return;
    }

    if (!this.fleetId) {
      this.formGroup.value.driver = this.sharedUserData.user._id;
    }

    this.fleetService.saveFleet(this.fleetId, this.formGroup.value).subscribe(
      (data) => {
        if (data) {
          Swal.fire({
            title: "Alert",
            html: "Operation successful",
            icon: "success",
          }).then((c) => {
        });
            if (data.driver && data.fleet) {
              this.sharedService.pushNotification({
                ...this.sharedUserData,
                user: data.driver,
                fleet: data.fleet,
              });
            } else {
              this.sharedService.pushNotification({
                ...this.sharedUserData,
                fleet: {
                  ...data
                },
                user: {
                  ...this.sharedUserData.user,
                  step: 2,
                },
              });
            }
         
        }
      },
      (err) => {
        Swal.fire({
          title: "Alert",
          html: "Operation failed",
          icon: "error",
          confirmButtonColor: "#F1A303",
        }).then((data) => {});
      }
    );
  }

  get form() {
    return this.formGroup.controls;
  }
}

import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { SharedDataService } from "src/app/services/shared-service/shared-data.service";
import { imageFileProcessing } from "src/app/utils/image-processing-utils";
import Swal from "sweetalert2";
import { DriverService } from "../service/driver.service";

@Component({
  selector: "app-add-driver-documents",
  templateUrl: "./add-driver-documents.component.html",
  styleUrls: ["./add-driver-documents.component.css"],
})
export class AddDriverDocumentsComponent implements OnInit {
  formGroup: FormGroup;
  isSubmitted = false;

  additionalInfoId;
  additionalInfo;

  licenseImageFile;
  licenseImageUrl;

  licenseBackImageFile;
  licenseBackImageUrl;

  taxiCabImageFile;
  taxiCabImageUrl;

  taxiCabBackImageFile;
  taxiCabBackImageUrl;

  sheriffLicenseImageFile;
  sheriffLicenseImageUrl;

  sheriffLicenseBackImageFile;
  sheriffLicenseBackImageUrl;

  vehicleRegistraionImageFile;
  vehicleRegistraionImageUrl;

  today;
  sharedUserData;

  constructor(
    private fb: FormBuilder,
    private driverService: DriverService,
    private sharedService: SharedDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.today = moment().set({hours:0,minutes:0,seconds:0}).toISOString();
    console.log('today', this.today);
    this.formGroup = this.fb.group({
      licenseNumber: ["", Validators.required],
      licenseExpiry: ["", Validators.required],
      preLicenseExpiry: [""],
      newLicenseImage: [null, Validators.required],
      newLicenseBackImage: [null, Validators.required],

      taxiCabExpiry: [""],
      preTaxiCabExpiry: [""],
      newTaxiCabImage: [null],
      newTaxiCabBackImage: [null],

      sheriffLicenseNumber: [""],
      sheriffLicenseExpiry: [""],
      preSheriffLicenseExpiry: [""],
      newSheriffLicenseImage: [""],
      newSheriffLicenseBackImage: [""],

      vehicleRegistrationExpiry: ["", Validators.required],
      preVehicleRegistrationExpiry: [""],
      newVehicleRegistrationImage: ["", Validators.required],
    });

    this.sharedService.getNotifiction().subscribe((data) => {
      if (data) {
        console.log('Shared Data: Driver Docs', data);
        this.sharedUserData = data;

        if (data.driveradditionalinfo) {
          this.additionalInfoId = data.driveradditionalinfo._id;
          this.additionalInfo = data.driveradditionalinfo;

          this.licenseImageUrl = data.driveradditionalinfo.licenseImageURL;
          this.licenseBackImageUrl = data.driveradditionalinfo.licenseImageBackURL;
          this.taxiCabImageUrl = data.driveradditionalinfo.taxiCabImageURL;
          this.taxiCabBackImageUrl = data.driveradditionalinfo.taxiCabBackImageURL;
          this.sheriffLicenseImageUrl = data.driveradditionalinfo.sheriffLicenseImageURL;
          this.sheriffLicenseBackImageUrl = data.driveradditionalinfo.sheriffLicenseBackImageURL;
          this.vehicleRegistraionImageUrl = data.driveradditionalinfo.vehicleRegistrationImageURL;

          this.formGroup.patchValue({
            ...data.driveradditionalinfo,
            preLicenseExpiry: data.driveradditionalinfo.licenseExpiry,
            preTaxiCabExpiry: data.driveradditionalinfo.taxiCabExpiry,
            preSheriffLicenseExpiry: data.driveradditionalinfo.sheriffLicenseExpiry,
            preVehicleRegistrationExpiry: data.driveradditionalinfo.vehicleRegistrationExpiry,
          });

          this.formGroup.controls.newLicenseImage.clearValidators();
          this.formGroup.controls.newLicenseImage.updateValueAndValidity();

          this.formGroup.controls.newLicenseBackImage.clearValidators();
          this.formGroup.controls.newLicenseBackImage.updateValueAndValidity();

          this.formGroup.controls.newVehicleRegistrationImage.clearValidators();
          this.formGroup.controls.newVehicleRegistrationImage.updateValueAndValidity(); 
        }
      }
    });
  }

  submit() {
    this.isSubmitted = true;

    if (this.additionalInfo) {
      this.formGroup.controls.preTaxiCabExpiry.clearValidators();
      this.formGroup.controls.preTaxiCabExpiry.updateValueAndValidity();

      this.formGroup.controls.preSheriffLicenseExpiry.clearValidators();
      this.formGroup.controls.preSheriffLicenseExpiry.updateValueAndValidity();
    }

    console.log("Form :", this.formGroup.value);
    console.log("Form Object :", this.formGroup);

    if (this.formGroup.invalid) {
      return;
    }

    console.log("Valid Form: ", this.formGroup.value);

    if (this.additionalInfoId) {
      this.formGroup.removeControl('taxiCabImageURL'); 
      this.formGroup.removeControl('taxiCabBackImageURL'); 
      this.formGroup.removeControl('sheriffLicenseImageURL'); 
      this.formGroup.removeControl('sheriffLicenseBackImageURL'); 

      if (!this.formGroup.value.newLicenseImage) {
        delete this.formGroup.value.newLicenseImage;
      }

      if (!this.formGroup.value.newLicenseBackImage) {
        delete this.formGroup.value.newLicenseBackImage;
      }

      // Optional (Taxi Card Front)
      if (this.additionalInfo.taxiCabImageURL) {
        // Image already exists

        // CASE: NO CHANGE
        if (this.taxiCabImageUrl && !this.formGroup.value.newTaxiCabImage) {
          delete this.formGroup.value.newTaxiCabImage;
        }

        // CASE: DELETE 
        if (!this.taxiCabImageUrl && !this.formGroup.value.newTaxiCabImage) {
          this.formGroup.addControl('taxiCabImageURL', new FormControl(''));
        }
      } else {
        // Image doesn't exist
        // CASE: NO CHANGE
        if (!this.formGroup.value.newTaxiCabImage) {
          delete this.formGroup.value.newTaxiCabImage;
        }
      }

      // Optional (Taxi Card Back)
      if (this.additionalInfo.taxiCabBackImageURL) {
        // Image already exists

        // CASE: NO CHANGE
        if (this.taxiCabBackImageUrl && !this.formGroup.value.newTaxiCabBackImage) {
          delete this.formGroup.value.newTaxiCabBackImage;
        }

        // CASE: DELETE 
        if (!this.taxiCabBackImageUrl && !this.formGroup.value.newTaxiCabBackImage) {
          this.formGroup.addControl('taxiCabBackImageURL', new FormControl(''));
        }
      } else {
        // Image doesn't exist
        // CASE: NO CHANGE
        if (!this.formGroup.value.newTaxiCabBackImage) {
          delete this.formGroup.value.newTaxiCabBackImage;
        }
      }

      // Optional (Sheriff License Front)
      if (this.additionalInfo.sheriffLicenseImageURL) {
        // Image already exists

        // CASE: NO CHANGE
        if (this.sheriffLicenseImageUrl && !this.formGroup.value.newSheriffLicenseImage) {
          delete this.formGroup.value.newSheriffLicenseImage;
        }

        // CASE: DELETE 
        if (!this.sheriffLicenseImageUrl && !this.formGroup.value.newSheriffLicenseImage) {
          this.formGroup.addControl('sheriffLicenseImageURL', new FormControl(''));
        }
      } else {
        // Image doesn't exist
        // CASE: NO CHANGE
        if (!this.formGroup.value.newSheriffLicenseImage) {
          delete this.formGroup.value.newSheriffLicenseImage;
        }
      }

      // Optional (Sheriff License Back)
      if (this.additionalInfo.sheriffLicenseBackImageURL) {
        // Image already exists

        // CASE: NO CHANGE
        if (this.sheriffLicenseBackImageUrl && !this.formGroup.value.newSheriffLicenseBackImage) {
          delete this.formGroup.value.newSheriffLicenseBackImage;
        }

        // CASE: DELETE 
        if (!this.sheriffLicenseBackImageUrl && !this.formGroup.value.newSheriffLicenseBackImage) {
          this.formGroup.addControl('sheriffLicenseBackImageURL', new FormControl(''));
        }
      } else {
        // Image doesn't exist
        // CASE: NO CHANGE
        if (!this.formGroup.value.newSheriffLicenseBackImage) {
          delete this.formGroup.value.newSheriffLicenseBackImage;
        }
      }

      if (!this.formGroup.value.newVehicleRegistrationImage) {
        delete this.formGroup.value.newVehicleRegistrationImage;
      }
    } else {
      this.formGroup.value.driver = this.sharedUserData.user._id;
    }

    this.driverService
      .saveDriverDocuments(this.additionalInfoId, this.formGroup.value)
      .subscribe(
        (data) => {
          this.isSubmitted = false;
          if (data) {
            Swal.fire({
              title: "Alert",
              html: "Operation successful",
              icon: "success",
            }).then((c) => {
          });

            if (data.driver && data.driveradditionalinfo) {
              this.sharedService.pushNotification({
                ...this.sharedUserData,
                user: data.driver,
                driveradditionalinfo: data.driveradditionalinfo,
              });
            }else{
              this.sharedService.pushNotification({
                ...this.sharedUserData,
                driveradditionalinfo: data,
                user: {
                  ...this.sharedUserData.user,
                  step: 1,
                },
              });
            }
          }
        },
        (err) => {
          this.isSubmitted = false;
          Swal.fire({
            title: "Alert",
            html: err,
            icon: "error",
          }).then((data) => {});
        }
      );
  }

  // License Image Front
  processLicenseImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.licenseImageFile = result.file;
        this.licenseImageUrl = result.src;
        this.formGroup.patchValue({
          newLicenseImage: this.licenseImageFile,
        });
      } else {
        this.clearLicenseImage();
      }
    });
  }

  clearLicenseImage() {
    this.licenseImageFile = null;
    this.licenseImageUrl = "";
    this.formGroup.patchValue({
      newLicenseImage: null,
    });

    if (this.additionalInfoId) {
      this.formGroup.controls.newLicenseImage.setValidators([
        Validators.required,
      ]);
      this.formGroup.controls.newLicenseImage.updateValueAndValidity();
    }
  }

  // License Image Back
  processLicenseBackImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.licenseBackImageFile = result.file;
        this.licenseBackImageUrl = result.src;
        this.formGroup.patchValue({
          newLicenseBackImage: this.licenseBackImageFile,
        });
      } else {
        this.clearLicenseBackImage();
      }
    });
  }

  clearLicenseBackImage() {
    this.licenseBackImageFile = null;
    this.licenseBackImageUrl = "";
    this.formGroup.patchValue({
      newLicenseBackImage: null,
    });

    if (this.additionalInfoId) {
      this.formGroup.controls.newLicenseBackImage.setValidators([
        Validators.required,
      ]);
      this.formGroup.controls.newLicenseBackImage.updateValueAndValidity();
    }
  }

  // Taxi Cab Image Front
  processTaxiCabImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.taxiCabImageFile = result.file;
        this.taxiCabImageUrl = result.src;
        this.formGroup.patchValue({
          newTaxiCabImage: this.taxiCabImageFile,
        });
      } else {
        this.clearTaxiCabImage();
      }
    });
  }

  clearTaxiCabImage() {
    this.taxiCabImageFile = null;
    this.taxiCabImageUrl = "";
    this.formGroup.patchValue({
      newTaxiCabImage: null,
    });
  }

  // Taxi Cab Image Back
  processTaxiCabBackImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.taxiCabBackImageFile = result.file;
        this.taxiCabBackImageUrl = result.src;
        this.formGroup.patchValue({
          newTaxiCabBackImage: this.taxiCabBackImageFile,
        });
      } else {
        this.clearTaxiCabBackImage();
      }
    });
  }

  clearTaxiCabBackImage() {
    this.taxiCabBackImageFile = null;
    this.taxiCabBackImageUrl = "";
    this.formGroup.patchValue({
      newTaxiCabBackImage: null,
    });
  }

  // Sheriff License Image Front
  processSheriffLicenseImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.sheriffLicenseImageFile = result.file;
        this.sheriffLicenseImageUrl = result.src;
        this.formGroup.patchValue({
          newSheriffLicenseImage: this.sheriffLicenseImageFile,
        });
      } else {
        this.clearSheriffLicenseImage();
      }
    });
  }

  clearSheriffLicenseImage() {
    this.sheriffLicenseImageFile = null;
    this.sheriffLicenseImageUrl = "";
    this.formGroup.patchValue({
      newSheriffLicenseImage: null,
    });
  }

  // Sheriff License Image Back
  processSheriffLicenseBackImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.sheriffLicenseBackImageFile = result.file;
        this.sheriffLicenseBackImageUrl = result.src;
        this.formGroup.patchValue({
          newSheriffLicenseBackImage: this.sheriffLicenseBackImageFile,
        });
      } else {
        this.clearSheriffLicenseBackImage();
      }
    });
  }

  clearSheriffLicenseBackImage() {
    this.sheriffLicenseBackImageFile = null;
    this.sheriffLicenseBackImageUrl = "";
    this.formGroup.patchValue({
      newSheriffLicenseBackImage: null,
    });
  }

  // Vehicle Registration Image
  processVehicleRegistrationImage(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log("Image Processing Result: ", result);
      if (result) {
        this.vehicleRegistraionImageFile = result.file;
        this.vehicleRegistraionImageUrl = result.src;
        this.formGroup.patchValue({
          newVehicleRegistrationImage: this.vehicleRegistraionImageFile,
        });
      } else {
        this.clearVehicleRegistrationImage();
      }
    });
  }

  clearVehicleRegistrationImage() {
    this.vehicleRegistraionImageFile = null;
    this.vehicleRegistraionImageUrl = "";
    this.formGroup.patchValue({
      newVehicleRegistrationImage: null,
    });

    if (this.additionalInfoId) {
      this.formGroup.controls.newVehicleRegistrationImage.setValidators([
        Validators.required,
      ]);
      this.formGroup.controls.newVehicleRegistrationImage.updateValueAndValidity();
    }
  }

  onDateChange(evt, control) {
    if (evt){
      control.setValue(moment(evt).set({hours:0,minutes:0,seconds:0}).toISOString());
    }
  }

  isDatePassed(date) {
    const isPassed = moment(date).isBefore(this.today);
    // console.log(date, this.today, isPassed);
    return isPassed;
  }

  clearTaxiCardExpiry() {
    this.formGroup.patchValue({
      preTaxiCabExpiry: '',
      taxiCabExpiry: ''
    });
  }

  clearSheriffLicenseExpiry() {
    this.formGroup.patchValue({
      preSheriffLicenseExpiry: '',
      sheriffLicenseExpiry: ''
    });
  }

  get form() {
    return this.formGroup.controls;
  }
}
